import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PasswordInput from "features/authentication/password-input";
import { useAuthentication } from "../firebase/auth";
import functions from "../firebase/functions";
import { useSelector, useDispatch } from "react-redux";
import { setStatus } from "features/authentication/authSlice";

const Register = (props) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [message, setMessage] = useState({ text: "", status: "waiting" });

	const [creatingUser, setCreatingUser] = useState(false);

	const dispatch = useDispatch();
	const { registerUser } = useAuthentication();

	useEffect(() => {
		if (creatingUser && email.length && password.length) {
			startRegistration();
			dispatch(setStatus("registering"));
		}
		function startRegistration() {
			registerUser(email, password, firstName, lastName)
				.then(() => {
					console.log("Notifying user");
					return new Promise((resolve) => {
						setMessage({
							text: "User created... redirecting",
							status: "success"
						});
						setTimeout(() => {
							resolve();
						}, 3000);
					});
				})
				.then(() => {
					dispatch(setStatus("authenticated"));
				})
				.catch((error) => {
					setMessage(error.message);
					setCreatingUser(false);
					dispatch(setStatus("error"));
				});
		}
	}, [creatingUser, email, password]);

	const handleSubmit = () => {
		if (creatingUser) {
			console.log("We're already creating a user!");
		} else if (
			email.length &&
			firstName.length &&
			lastName.length &&
			password.length
		) {
			setCreatingUser(true);
		} else {
			setMessage({
				text: "need to complete the form properly before submitting!",
				status: "warning"
			});
		}
	};

	return (
		<>
			<div
				className='authentication col-12 d-flex flex-column justify-content-center align-items-center'
				style={{ height: "calc(100vh - 52px)" }}
			>
				<img
					className='login-logo mb-3 col-8 col-sm-5 col-md-3 mx-auto'
					alt='Daily Checklist'
					src='/images/daily-checklist-logo.png'
				/>
				<h1 className='login-title h3 mt-2 mb-4'>Register New Account</h1>
				<form
					className='col-lg-6 col-xl-4 col-md-9 col-12 px-3'
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
					aria-describedby='form_error'
				>
					<fieldset disabled={creatingUser}>
						<div className='form-group mb-3'>
							<label htmlFor='firstName' className='form-label'>
								First Name
							</label>
							<input
								className='form-control'
								aria-describedby='firstName'
								placeholder='eg: Joe'
								type='text'
								id='firstName'
								value={firstName}
								minLength={1}
								onChange={(e) => {
									setFirstName(e.target.value);
								}}
								autoFocus={true}
								required
							/>
						</div>
						<div className='form-group mb-3'>
							<label htmlFor='lastName' className='form-label'>
								Last Name
							</label>
							<input
								className='form-control'
								aria-describedby='firstName'
								placeholder='eg: Citizen'
								type='text'
								id='lastName'
								value={lastName}
								minLength={1}
								onChange={(e) => {
									setLastName(e.target.value);
								}}
								required
							/>
						</div>
						<div className='form-group mb-3'>
							<label htmlFor='emailAddress' className='form-label'>
								Email address
							</label>
							<input
								className='form-control'
								aria-describedby='emailHelp'
								placeholder='eg: john@dailychecklist.com.au'
								type='email'
								id='emailAddress'
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								required
							/>
						</div>
						<PasswordInput password={password} setPassword={setPassword} />

						<button
							type='submit'
							className='btn btn-primary col-12 mb-3'
							disabled={creatingUser}
						>
							Register!
						</button>
						{message.status !== "waiting" && (
							<div className={`alert alert-${message.status}`} role='alert'>
								{message.text}
							</div>
						)}
					</fieldset>
				</form>

				<p className='new-user'>
					Already have an account? <Link to='/login'>Login here</Link>
				</p>
			</div>
		</>
	);
};

export default Register;
