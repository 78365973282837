import firebase from "./firebase";

const db = firebase.firestore();
if (process.env.NODE_ENV === "development") {
	db.useEmulator("localhost", 8080);
}

function loadChecklist(userId, accountId, checklistId) {
	return db
		.collection("accounts")
		.doc(accountId)
		.collection("users")
		.doc(userId)
		.collection("checklists")
		.doc(checklistId);
}

export { db, loadChecklist };
