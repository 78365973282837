import { configureStore, combineReducers } from "@reduxjs/toolkit";
import checklistReducer from "../features/checklist/checklistSlice";
import timerReducer from "../features/timer/timerSlice";
import authReducer from "features/authentication/authSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["checklists", "timer", "authentication"]
};

const appReducer = combineReducers({
	checklists: checklistReducer,
	timer: timerReducer,
	authentication: authReducer
});

export const store = configureStore({
	reducer: persistReducer(persistConfig, appReducer),
	middleware: [thunk]
});
