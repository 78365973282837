import { auth } from "../firebase/firebase";
import { db } from "../firebase/db";
import { useSelector, useDispatch } from "react-redux";
import { setStatus, setUser, setData } from "features/authentication/authSlice";
import { removeChecklists } from "features/checklist/checklistSlice";
import { functions } from "../firebase/firebase";

export const useAuthentication = () => {
	const user = useSelector((state) => state.authentication.user);
	const status = useSelector((state) => state.authentication.status);
	const dispatch = useDispatch();

	function registerUser(email, password, firstName, lastName) {
		return new Promise((resolve, reject) => {
			auth
				.createUserWithEmailAndPassword(email, password)
				.then((user) => {
					const data = {
						firstName,
						lastName,
						userId: user.user.uid
					};

					return functions
						.httpsCallable("registerUser")(data)
						.then(() => {
							console.log("User created");
						});
				})
				.then(() => {
					resolve(true);
				})
				.catch((error) => {
					console.log(error);
					reject(false);
				});
		});
	}

	function loginUser(email, password) {
		return new Promise((resolve, reject) => {
			auth
				.signInWithEmailAndPassword(email, password)
				.catch((error) => {
					//Do something with the error if you want!
					console.log(error);
					reject(false);
				})
				.then((user) => {
					if (user) {
						console.log("user logged in");
						resolve(user);
					} else {
						reject(false);
					}
				});
		});
	}

	function logoutUser() {
		return new Promise((resolve, reject) => {
			auth
				.signOut()
				.then(() => {
					console.log("User signed out!");
					dispatch(setStatus("idle"));
					dispatch(setUser(null));
					dispatch(setData({}));
					dispatch(removeChecklists());
					resolve(true);
				})
				.catch((error) => {
					console.log(error);
					reject(false);
				});
		});
	}

	function userStatus() {
		return auth.onAuthStateChanged((user) => {
			if (user !== null) {
				return true;
			} else {
				return false;
			}
		});
	}

	async function getUserDetails() {
		console.log("Getting user details", user);
		const data = db.collection("users").doc(user);
		const userData = await data.get().then((doc) => {
			if (doc.exists) {
				const data = doc.data();
				console.log(data);
				return data;
			} else {
				return false;
			}
		});
		return userData;
	}
	return { registerUser, loginUser, logoutUser, userStatus, getUserDetails };
};
