import firebase from "./firebase";

const messaging = firebase.messaging();

const messagingKey =
	"BOGz-vpEmZ0HhYmaj9akw_UCV9eTc78RCMGbRnSGt1iaWIhwUdJvqMYOlTMdGOklcYfzJwHsAXNdbjd1zaG_7ZM";

const getToken = () => {
	return messaging
		.getToken({
			vapidKey: messagingKey
		})
		.then((currentToken) => {
			if (currentToken) {
				console.log("current token for client: ", currentToken);
				return currentToken;
				// Track the token -> client mapping, by sending to backend server
				// show on the UI that permission is secured
			} else {
				console.log(
					"No registration token available. Request permission to generate one."
				);
				return null;
				// shows on the UI that permission is required
			}
		})
		.catch((err) => {
			console.log("An error occurred while retrieving token. ", err);
			return null;
			// catch error while creating client token
		});
};

export { messaging, getToken };
